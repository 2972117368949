@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital@0;1&amp');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&amp');
@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy:ital@0;1&amp;display=swap');
@import url('https://db.onlinewebfonts.com/c/1d64aadb5ee4d128e7c17eeeb473aa46?family=Tibere+OT+W01+Light');
@import url('https://db.onlinewebfonts.com/c/bb882fe840a44ef460467ef745fa505e?family=Tibere+OT+W03+Medium');
@import url('https://db.onlinewebfonts.com/c/4d44487b9460a82d49a4297a2d8d6f87?family=LTC+Goudy+Oldstyle+W01+Italic');

body {
  margin: 0;
  font-family: "Sorts Mill Goudy", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-page-plugins-content-image {
  width: 100%
}

.hide-resize-observer {
  display: none !important;
}

.react-page-plugins-content-divider {
  background-color: #aaa;
  width: 100%;
  height: 2px;
  border: none;
}

.editable-area code,
.editable-area pre {
  font-family: monospace;
  border-radius: 0.3em;
  padding: 0.4em;
}

.editable-area code {
  display: inline;
  margin: 0 0.5em;
  white-space: pre;
}

.editable-area pre {
  display: block;
  margin: 1em;
}

.editable-area ul,
.editable-area ol {
  margin: 1em 0;
  list-style-type: inside;
}

.editable-area li {
  margin: 0.2em 0 0.2em 1em;
}

.editable-area li p {
  margin: 0;
}

.editable-area ol {
  list-style-type: decimal;
}

.editable-area ul {
  list-style-type: disc;
}


/* http://typecast.com/blog/a-more-modern-scale-for-web-typography */

:root {
  /* equivalent to 16px */
  /* equivalent to 20px */
}

body {
  font-size: 100%;
}

body,
caption,
th,
td,
input,
textarea,
select,
option,
legend,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size-adjust: 0.5;
}

.editable-area p,
.editable-area h1,
.editable-area h2,
.editable-area h3,
.editable-area h4,
.editable-area h5,
.editable-area h6,
.editable-area blockquote,
.editable-area pre {
  margin: 1em 0 0.5em;
}

.editable-area p:first-child,
.editable-area h1:first-child,
.editable-area h2:first-child,
.editable-area h3:first-child,
.editable-area h4:first-child,
.editable-area h5:first-child,
.editable-area h6:first-child,
.editable-area blockquote:first-child,
.editable-area pre:first-child {
  margin-top: 0;
}

.editable-area p:last-child,
.editable-area h1:last-child,
.editable-area h2:last-child,
.editable-area h3:last-child,
.editable-area h4:last-child,
.editable-area h5:last-child,
.editable-area h6:last-child,
.editable-area blockquote:last-child,
.editable-area pre:last-child {
  margin-bottom: 0;
}

.editable-area ul,
.editable-area ol {
  margin: 0;
}

.editable-area li {
  margin: 1em 0;
}

.editable-area body {
  font-size: 1em;
  line-height: 1.25em;
}

.editable-area em {
  font-style: italic;
}

.editable-area strong {
  font-weight: 700;
}

.editable-area h1 strong,
.editable-area h2 strong,
.editable-area h3 strong,
.editable-area h4 strong,
.editable-area h5 strong,
.editable-area h6 strong {
  font-weight: 800;
}

@media only screen and (max-width: 64em) and (min-width: 48em) {
  .editable-area body {
    font-size: 1em;
    line-height: 1.375em;
  }
}

.editable-area h1 {
  margin: 1em 0 0.5em 0;
  font-size: 2em;
  line-height: 1.25em;
}

.editable-area h1:first-child {
  margin-top: 0;
}

@media only screen and (max-width: 64em) and (min-width: 48em) {
  .editable-area h1 {
    font-size: 2.5em;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
}

@media only screen and (max-width: 64em) and (min-width: 48em) {
  .editable-area h1 {
    font-size: 3em;
    /* 3x body copy size = 48px */
    line-height: 1.05;
    /* keep to a multiple of the 20px line height and something more appropriate for display headings */
  }
}

.editable-area h2 {
  margin: 0.8em 0 0.5em 0;
  font-size: 1.625em;
  /* 1.625x body copy size = 26px */
  line-height: 1.15384615;
  /* 30px / 26px */
}

@media only screen and (max-width: 64em) and (min-width: 48em) {
  .editable-area h2 {
    font-size: 2em;
    /* 2x body copy size = 32px */
    line-height: 1.25em;
  }
}

@media only screen and (max-width: 48em) {
  .editable-area h2 {
    font-size: 2.25em;
    /* 2.25x body copy size = 36px */
    line-height: 1.25em;
  }
}

.editable-area h3 {
  margin: 0.8em 0 0.5em 0;
  font-size: 1.375em;
  /* 1.375x body copy size = 22px */
  line-height: 1.13636364;
  /* 25px / 22px */
}

@media only screen and (max-width: 64em) and (min-width: 48em) {
  .editable-area h3 {
    font-size: 1.5em;
    /* 1.5x body copy size = 24px */
    line-height: 1.25em;
  }
}

@media only screen and (max-width: 48em) {
  .editable-area h3 {
    font-size: 1.75em;
    /* 1.75x body copy size = 28px */
    line-height: 1.25em;
  }
}

.editable-area h4 {
  margin: 0.8em 0 0.5em 0;
  font-size: 1.125em;
  /* 1.125x body copy size = 18px */
  line-height: 1.11111111;
}

@media only screen and (max-width: 64em) and (min-width: 48em) {
  .editable-area h4 {
    line-height: 1.25em;
  }
}

@media only screen and (max-width: 48em) {
  .editable-area h4 {
    line-height: 1.25em;
  }
}

.editable-area h5 {
  margin: 0.8em 0 0.5em 0;
  font-size: 1.085em;
  /* 1.125x body copy size = 18px */
  line-height: 1.055;
}

@media only screen and (max-width: 64em) and (min-width: 48em) {
  .editable-area h5 {
    line-height: 1.25em;
  }
}

@media only screen and (max-width: 48em) {
  .editable-area h5 {
    line-height: 1.25em;
  }
}

.editable-area h6 {
  margin: 0.8em 0 0.5em 0;
  font-size: 1.055em;
  /* 1.125x body copy size = 18px */
  line-height: 1.005;
}

@media only screen and (max-width: 64em) and (min-width: 48em) {
  .editable-area h6 {
    line-height: 1.25em;
  }
}

@media only screen and (max-width: 48em) {
  .editable-area h6 {
    line-height: 1.25em;
  }
}

.editable-area blockquote {
  font-size: 1em;
  font-style: italic;
  padding: 0 2.5em;
}

body {
  margin: 0px;

  color: #333333;
  font-size: 18px;
  line-height: 28px;

  font-family: 'Open Sans', serif;
}

p {
  margin: 0 0px;
}

pre {
  overflow-x: auto;
}

a {
  color: #c73036;
  font-family: Georgia, serif;
  text-decoration: underline;
}

a:hover {
  color: #333333;
  text-decoration: underline;
}

.react-page-editable-mode-edit {
  width: 80% !important;
  border: 1px dashed rgb(128, 128, 128)
}

.render-html-content h2 {
  border-bottom: 2px solid #d2232a;
  margin-bottom: 1rem;
}

.render-html-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.render-html-content iframe {
  width: 100% !important;
  object-fit: contain;
  /* height: 100%!important; */
}

.render-html h2 {
  border-bottom: 2px solid #d2232a;
  margin-bottom: 1rem;
}

.render-html iframe {
  width: 100% !important;
  object-fit: contain;
  /* height: 100%!important; */
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: Barlow, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(0, 0, 26, .075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  background-color: var(--bs-btn-bg);
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  color: var(--bs-btn-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  text-align: center;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}

.btn-group-lg>.btn,
.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-custom {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ec4b49;
  --bs-btn-border-color: #e83231;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c9403e;
  --bs-btn-hover-border-color: #ba2827;
  --bs-btn-focus-shadow-rgb: 235, 81, 80;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #bd3c3a;
  --bs-btn-active-border-color: #ae2625;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 26, .125);
  --bs-btn-disabled-color: #00001a;
  --bs-btn-disabled-bg: #ec4b49;
  --bs-btn-disabled-border-color: #e83231;
  font-size: 1.875rem;
  padding-right: 7rem;
  position: relative;
}

.btn-custom:after {
  align-items: center;
  background-color: #e83231;
  bottom: 0;
  content: "\f101";
  display: flex;
  font-family: Font Awesome\ 6 Free;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.btn-custom:hover {
  background-color: #c9403e;
  border-color: #ba2827;
  color: #fff;
}

.widget-button-dark-outline {
  border: 2px solid #231f20;
  color: #231f20;
  display: block;
  font-family: Barlow, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 1.75rem;
}

.widget-button-dark-outline:hover {
  color: #e83231;
  text-decoration: none;
  border: 2px solid #e83231;
}

.border-black {
  border: 15px solid #231f20;
}

.testimonial-content img {
  border: 15px solid #231f20;
  margin-bottom: 10px;
}

.img-fit-size {
  object-fit: contain;
}

.resource-description.resource-text {

  /* font-size: '1.5rem'; */
  /* line-height: 1.25; */
}

.resource-description.resource-text p {
  font-family: 'Sorts Mill Goudy, serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;';
  margin-bottom: 10px;
  font-size: '1.5rem' !important;
}