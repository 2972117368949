div.image-box {
    width: 47%;
    background-color: #f9f1df;
    margin-right: 2.5%;
    display: inline-block;
    box-sizing: border-box;
    font-family: 'Sorts Mill Goudy', serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 18px;
    color: #231f20;
    vertical-align: top;
}

div.side-image {
    width: 100% !important;
}

div.image-box img {
    border: 15px solid #000;
    margin-top: 20px;
    margin-bottom: 20px;
}